import { LEDN_BONUS_HTML } from '../utils/constants';
import { ratesUS as newUS, ratesInt as newInt, lednDateEffective } from './ledn-rates/2-1-25';
import { ratesUS as oldUS, ratesInt as oldInt } from './ledn-rates/11-1-24';

export const lednAdditionalInfoInt = 'may take up to 7 business days to withdraw';
const availabilityInfoUS = 'Ledn is no longer available for earning interest in the United States';

export const lednWithdrawals = {
    BTC: 'network fees',
    USDC: '15 USDC',
    USDT: '15 USDT',
    ETH: '0.009 ETH',
};

export const lednInfoInt = {
    name: 'Ledn',
    additionalInfo: lednAdditionalInfoInt,
    icon: '/images/ledn-logo.jpeg',
    order: 9,
    bonus: '$10 in USDC',
    bonusLink: 'ledn-bonus',
    referralSlug: 'ledn-referral',
};

export const lednRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Ledn',
    bonusHTML: LEDN_BONUS_HTML,
    dateEffectiveUS: lednDateEffective,
    dateEffectiveInt: lednDateEffective,
    availabilityInfoUS,
    key: 'ledn',
};
