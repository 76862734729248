export const geminiDateEffective = '10/5/22';

export const ratesUS = {
    '1INCH': { base: '8.05%', max: '8.05%' },
    AAVE: { base: '1.27%', max: '1.27%' },
    ALCX: { base: '6.18%', max: '6.18%' },
    AMP: { base: '0.45%', max: '0.45%' },
    ANKR: { base: '1.24%', max: '1.24%' },
    APE: { base: '6.10%', max: '6.10%' },
    AXS: { base: '6.83%', max: '6.83%' },
    BAL: { base: '6.12%', max: '6.12%' },
    BAT: { base: '1.01%', max: '1.01%' },
    BCH: { base: '5.33%', max: '5.33%' },
    BNT: { base: '7.15%', max: '7.15%' },
    BTC: { base: '2.75%', max: '2.75%' },
    CHZ: { base: '1.49%', max: '1.49%' },
    COMP: { base: '2.28%', max: '2.28%' },
    CRV: { base: '6.24%', max: '6.24%' },
    DAI: { base: '4.92%', max: '4.92%' },
    DOGE: { base: '1.01%', max: '1.01%' },
    ETH: { base: '2.53%', max: '2.53%' },
    FET: { base: '6.18%', max: '6.18%' },
    FIL: { base: '7.25%', max: '7.25%' },
    S: { base: '1.27%', max: '1.27%' },
    GRT: { base: '2.50%', max: '2.50%' },
    GUSD: { base: '5.92%', max: '5.92%' },
    INJ: { base: '1.25%', max: '1.25%' },
    KNC: { base: '1.25%', max: '1.25%' },
    LINK: { base: '0.45%', max: '0.45%' },
    LPT: { base: '0.74%', max: '0.74%' },
    LRC: { base: '4.03%', max: '4.03%' },
    LTC: { base: '1.51%', max: '1.51%' },
    MANA: { base: '0.75%', max: '0.75%' },
    MATIC: { base: '5.13%', max: '5.13%' },
    MIR: { base: '0.50%', max: '0.50%' },
    MKR: { base: '1.26%', max: '1.26%' },
    OXT: { base: '0.45%', max: '0.45%' },
    PAXG: { base: '0.45%', max: '0.45%' },
    RBN: { base: '1.94%', max: '1.94%' },
    REN: { base: '0.45%', max: '0.45%' },
    RLY: { base: '6.18%', max: '6.18%' },
    SKL: { base: '1.49%', max: '1.49%' },
    SNX: { base: '6.18%', max: '6.18%' },
    SOL: { base: '5.06%', max: '5.06%' },
    STORJ: { base: '3.05%', max: '3.05%' },
    SUSHI: { base: '2.50%', max: '2.50%' },
    TOKE: { base: '2.24%', max: '2.24%' },
    UMA: { base: '0.45%', max: '0.45%' },
    UNI: { base: '1.01%', max: '1.01%' },
    USDC: { base: '5.13%', max: '5.13%' },
    XTZ: { base: '3.00%', max: '3.00%' },
    YFI: { base: '1.73%', max: '1.73%' },
    ZEC: { base: '0.45%', max: '0.45%' },
    ZRX: { base: '1.25%', max: '1.25%' },
}

export const ratesInt = ratesUS;
