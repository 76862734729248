import React from 'react';
import { BLOCKFI_BONUS_HTML } from '../utils/constants';
import { ratesInt as newInt, blockfiDateEffectiveInt } from './blockfi-rates/11-1-22';
import { ratesInt as oldInt } from './blockfi-rates/9-1-22';
import { ratesUS as newUS, blockfiDateEffective } from './blockfi-rates/7-1-22';
import { ratesUS as oldUS } from './blockfi-rates/6-1-22';

// const freeWithdrawal = ', but 1 free crypto withdrawal per month';
const blockfiCantWithdraw = 'cannot be deposited or withdrawn, only bought or sold through BlockFi';
const availabilityInfoUS = 'BlockFi US customers can no longer earn interest on new deposits; pre-existing deposits can continue to earn interest';
const blockfiPausedWithdrawals = <span>BlockFi has paused client withdrawals; <a target="_blank" rel="noopener" href="https://blockfi.com/november-11-2022-blockfi-update">BlockFi Announcement</a></span>;

export const blockfiWithdrawalsRaw = {
    ETH: '0.0135 ETH',
    LINK: '2 LINK',
    PAXG: '0.035 PAXG',
    UNI: '2.5 UNI',
    BAT: '60 BAT',
    BTC: '0.00025 BTC',
    LTC: '0.001 LTC',
    USDC: '$25 USD',
    GUSD: '$25 USD',
    PAX: '$25 USD',
    DAI: '$25 USD',
    BUSD: '$25 USD',
    USDT: '$25 USD',
    ADA: blockfiCantWithdraw,
    SOL: blockfiCantWithdraw,
    DOT: blockfiCantWithdraw,
    AVAX: blockfiCantWithdraw,
    ATOM: blockfiCantWithdraw,
    FIL: blockfiCantWithdraw,
    BCH: blockfiCantWithdraw,
    DOGE: blockfiCantWithdraw,
    MATIC: blockfiCantWithdraw,
    AAVE: blockfiCantWithdraw,
    AXS: blockfiCantWithdraw,
    BNB: blockfiCantWithdraw,
    EOS: blockfiCantWithdraw,
    S: blockfiCantWithdraw,
    LUNA: blockfiCantWithdraw,
    MANA: blockfiCantWithdraw,
    NEAR: blockfiCantWithdraw,
    FTT: blockfiCantWithdraw,
    ALGO: blockfiCantWithdraw,
    TRX: blockfiCantWithdraw,
    SAND: blockfiCantWithdraw,
    GALA: blockfiCantWithdraw,
    RUNE: blockfiCantWithdraw,
    SRM: blockfiCantWithdraw,
    HBAR: blockfiCantWithdraw,
    XTZ: blockfiCantWithdraw,
    LRC: blockfiCantWithdraw,
    OMG: blockfiCantWithdraw,
    MKR: blockfiCantWithdraw,
    COMP: blockfiCantWithdraw,
    YFI: blockfiCantWithdraw,
    CRV: blockfiCantWithdraw,
    SUSHI: blockfiCantWithdraw,
    '1INCH': blockfiCantWithdraw,
    GRT: blockfiCantWithdraw,
    CHZ: blockfiCantWithdraw,
    ENJ: blockfiCantWithdraw,
};

export const blockfiWithdrawals = blockfiWithdrawalsRaw;

// export const blockfiWithdrawals = {
//     ...blockfiWithdrawalsRaw,
//     BTC: blockfiWithdrawalsRaw.BTC + freeWithdrawal,
//     LTC: blockfiWithdrawalsRaw.LTC + freeWithdrawal,
//     USDC: blockfiWithdrawalsRaw.USDC + freeWithdrawal,
//     GUSD: blockfiWithdrawalsRaw.GUSD + freeWithdrawal,
//     PAX: blockfiWithdrawalsRaw.PAX + freeWithdrawal,
//     DAI: blockfiWithdrawalsRaw.DAI + freeWithdrawal,
//     BUSD: blockfiWithdrawalsRaw.BUSD + freeWithdrawal,
//     USDT: blockfiWithdrawalsRaw.USDT + freeWithdrawal,
// };

export const blockfiRowInfoUS = {
    name: 'BlockFi',
    additionalInfo: availabilityInfoUS,
    icon: '/images/blockfi-logo.svg',
    order: 3,
    bonus: 'up to $250 in BTC',
    bonusLink: 'blockfi-bonus',
    referralSlug: 'blockfi-referral',
};

export const blockfiRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'BlockFi',
    // bonusHTML: BLOCKFI_BONUS_HTML,
    dateEffectiveUS: blockfiDateEffective,
    dateEffectiveInt: blockfiDateEffectiveInt,
    availabilityInfoUS: blockfiPausedWithdrawals,
    availabilityInfoInt: blockfiPausedWithdrawals,
    key: 'blockfi',
};
