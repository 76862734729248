import React from 'react';
import { GEMINI_BONUS_HTML } from '../utils/constants';
import { ratesUS as newUS, ratesInt as newInt, geminiDateEffective } from './gemini-rates/11-1-22';
import { ratesUS as oldUS, ratesInt as oldInt } from './gemini-rates/10-5-22';

// const baseWithdrawalFee = 'free to withdraw from Gemini Earn to your Gemini Trading Balance; 10 free withdrawals per month from Gemini Trading, then ';
// const baseWithdrawalFee = 'must redeem from Gemini Earn to Trading; 10 free withdrawals per month from Gemini Trading, then ';
const baseWithdrawalFee = '10 free crypto withdrawals per month, then ';

const geminiEarnPausedWithdrawals = <span>The lending partner of Gemini Earn (<a target="_blank" rel="noopener" href="https://www.coindesk.com/business/2022/11/16/genesis-crypto-lending-unit-is-halting-customer-withdrawals-in-wake-of-ftx-collapse/">Genesis</a>) has paused withdrawals (this only affects Gemini Earn, not any other Gemini products / services); <a target="_blank" rel="noopener" href="https://www.gemini.com/blog/an-important-message-regarding-gemini-earn">Gemini Announcement</a></span>;

export const geminiRowInfoUS = {
    name: 'Gemini Earn',
    additionalInfo: 'must redeem from Gemini Earn to Gemini Trading (can take up to 5 business days) before withdrawing',
    icon: '/images/gemini-logo.svg',
    order: 8,
    bonus: '$50 in crypto',
    bonusLink: 'gemini-bonus',
    referralSlug: 'gemini-referral',
};

export const geminiWithdrawalsRaw = {
    BTC: '0.0001 BTC',
    ETH: 'network fees',
    LTC: '0.001 LTC',
    LINK: 'network fees',
    USDC: 'network fees',
    BCH: '0.001 BCH',
    MATIC: 'network fees',
    MANA: 'network fees',
    AAVE: 'network fees',
    DAI: 'network fees',
    GUSD: 'none',
    BAT: 'network fees',
    UNI: 'network fees',
    DOGE: '4 DOGE',
    XTZ: '0.4 XTZ',
    GRT: 'network fees',
    COMP: 'network fees',
    MKR: 'network fees',
    PAXG: 'network fees',
    ZRX: 'network fees',
    LUNA: 'network fees',
    SUSHI: 'network fees',
    ZEC: '0.001 ZEC',
    SNX: 'network fees',
    '1INCH': 'network fees',
    UMA: 'network fees',
    KNC: 'network fees',
    OXT: 'network fees',
    FIL: '0.001 FIL',
    S: 'network fees',
    AXS: 'network fees',
    SOL: '0.005 SOL',
    UST: 'network fees',
    APE: 'network fees',
    YFI: 'network fees',
    CRV: 'network fees',
    ALCX: 'network fees',
    AMP: 'network fees',
    ANKR: 'network fees',
    BAL: 'network fees',
    INJ: 'network fees',
    LPT: 'network fees',
    MIR: 'network fees',
    REN: 'network fees',
    RLY: 'network fees',
    STORJ: 'network fees',
    BNT: 'network fees',
    TOKE: 'network fees',
    RBN: 'network fees',
    CHZ: 'network fees',
    FET: 'network fees',
    LRC: 'network fees',
    SKL: 'network fees',
};

export const geminiWithdrawals = geminiWithdrawalsRaw;
// for (let key in geminiWithdrawalsRaw) {
//     const value = geminiWithdrawalsRaw[key];
//     geminiWithdrawals[key] = baseWithdrawalFee + value;
//     if (value === 'none') {
//         geminiWithdrawals[key] = value;
//     }
// }

export const geminiAdditionalInfoInt = 'only available in the US, Hong Kong, and Singapore; ' + geminiRowInfoUS.additionalInfo;

export const geminiRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Gemini Earn',
    bonusHTML: GEMINI_BONUS_HTML,
    dateEffectiveUS: geminiDateEffective,
    dateEffectiveInt: geminiDateEffective,
    availabilityInfoUS: geminiEarnPausedWithdrawals,
    availabilityInfoInt: geminiEarnPausedWithdrawals,
    key: 'gemini',
};
