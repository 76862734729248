export const cryptoComDateEffective = '3/12/25';

const tier1Limit = ' on first $3,000 of total assets in fixed terms';
const earnPlusLImit = ' on up to $500,000'

export const ratesUS = {
    CRO: {
        base: '0.25%',
        max: `6.00%${tier1Limit} - requires locking asset for 3 months`,
    },
    DAI: {
        base: '0.25%',
        max: '7.00% (5.00% in kind + 2% in CRO)' + tier1Limit,
    },
    USDC: { base: '0.50%', max: '4.20%' + earnPlusLImit },
    USC: { base: '0.50%', max: '4.20%' + earnPlusLImit },
    USDT: { base: '0.50%', max: '4.20%' + earnPlusLImit },
    BTC: {
        base: '0.25%',
        max: '5.00% (3.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ETH: {
        base: '0.25%',
        max: '5.75% (3.75% in kind + 2% in CRO)' + tier1Limit,
    },
    ALGO: {
        base: '0.25%',
        max: '3.00% (1.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ATOM: {
        base: '0.50%',
        max: '11.00% (9.00% in kind + 2% in CRO)' + tier1Limit,
    },
    S: {
        base: '0.25%',
        max: '5.00% (3.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ADA: {
        base: '0.25%',
        max: '5.50% (3.50% in kind + 2% in CRO)' + tier1Limit,
    },
    BNB: {
        base: '0.25%',
        max: '3.25% (1.25% in kind + 2% in CRO)' + tier1Limit,
    },
    VET: {
        base: '0.25%',
        max: '3.25% (1.25% in kind + 2% in CRO)' + tier1Limit,
    },
    EGLD: {
        base: '0.25%',
        max: '3.75% (1.75% in kind + 2% in CRO)' + tier1Limit,
    },
    DOT: {
        base: '0.50%',
        max: '11.00% (9.00% in kind + 2% in CRO)' + tier1Limit,
    },
    MATIC: {
        base: '0.25%',
        max: '5.00% (3.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ONE: {
        base: '0.25%',
        max: '5.50% (3.50% in kind + 2% in CRO)' + tier1Limit,
    },
    AVAX: {
        base: '0.25%',
        max: '7.50% (5.50% in kind + 2% in CRO)' + tier1Limit,
    },
    NEAR: {
        base: '0.25%',
        max: '5.50% (3.50% in kind + 2% in CRO)' + tier1Limit,
    },
    SOL: {
        base: '0.25%',
        max: '7.25% (5.25% in kind + 2% in CRO)' + tier1Limit,
    },
    STX: {
        base: '0.25%',
        max: '8.50% (6.50% in kind + 2% in CRO)' + tier1Limit,
    },
    LION: {
        base: '0%',
        max: '60.00% - requires locking asset for 12 months',
    },
    BARA: {
        base: '0%',
        max: '30.00% - requires locking asset for 3 months',
    },
};

export const ratesInt = {
    ...ratesUS,
    APT: {
        base: '0.25%',
        max: '6.50% (4.50% in kind + 2% in CRO)' + tier1Limit,
    },
    ZIL: {
        base: '0.25%',
        max: '8.00% (6.00% in kind + 2% in CRO)' + tier1Limit,
    },
};
