import React from 'react';

const maxBlockfiBitcoinAPY = '3.5% on first 0.1 BTC';
const blockfiBitcoinAPY = <div>{maxBlockfiBitcoinAPY} <br />2% for over 0.1 BTC</div>;

const maxBlockfiEthAPY = '3.5% on first 1.5 ETH';
const blockfiEthAPY = <div>{maxBlockfiEthAPY} <br />2% for over 1.5 ETH</div>;

const maxBlockfiLitecoinAPY = '2% on first 20 LTC';
const blockfiLitecoinAPY = <div>{maxBlockfiLitecoinAPY} <br />1% for 20 to 100 LTC <br />0.1% for over 100 LTC</div>;

const maxblockfiChainlinkAPY = '1% on first 100 LINK';
const blockfiChainlinkAPY = <div>{maxblockfiChainlinkAPY} <br />0.2% for 100 to 500 LINK <br />0.1% for over 500 LINK</div>;

const maxblockfiPaxosGoldAPY = '1% on first 1.5 PAXG';
const blockfiPaxosGoldAPY = <div>{maxblockfiPaxosGoldAPY} <br />0.2% for 1.5 to 5 PAXG <br />0.1% for over 5 PAXG</div>;

const maxBlockfiUniswapAPY = '1% on first 100 UNI';
const blockfiUniswapAPY = <div>{maxBlockfiUniswapAPY} <br />0.2% for 100 to 500 UNI <br />0.1% for over 500 UNI</div>;

const blockfiBatAPY = <div>1.00% on first 4k BAT <br />0.2% for 4k to 20k BAT <br />0.1% for over 20k BAT</div>;

const maxBlockfiUSDCoinAPY = '7.5% on first 20k USDC'
const blockfiUSDCoinAPY = <div>{maxBlockfiUSDCoinAPY} <br />6% for over 20k USDC</div>;

const maxBlockfiGeminiDollarsAPY = '7.5% on first 20k GUSD'
const blockfiGeminiDollarsAPY = <div>{maxBlockfiGeminiDollarsAPY} <br />6% for over 20k GUSD</div>;

const maxBlockfiPaxosAPY = '7.5% on first 20k PAX'
const blockfiPaxosAPY = <div>{maxBlockfiPaxosAPY} <br />6% for over 20k PAX</div>;

const maxBlockfiBinanceUSDAPY = '7.5% on first 20k BUSD'
const blockfiBinanceUSDAPY = <div>{maxBlockfiBinanceUSDAPY} <br />6% for over 20k BUSD</div>;

const maxBlockfiDaiAPY = '6% on first 20k DAI'
const blockfiDaiAPY = <div>{maxBlockfiDaiAPY} <br />4% for 20k to 2M DAI<br />3.5% for over 2M DAI</div>;

export const blockfiDateEffectiveInt = '11/1/22';

export const ratesUS = {
    'BTC': {
        base: blockfiBitcoinAPY,
        max: maxBlockfiBitcoinAPY,
    },
    'ETH': {
        base: blockfiEthAPY,
        max: maxBlockfiEthAPY,
    },
    'LTC': {
        base: blockfiLitecoinAPY,
        max: maxBlockfiLitecoinAPY,
    },
    'LINK': {
        base: blockfiChainlinkAPY,
        max: maxblockfiChainlinkAPY,
    },
    'USDC': {
        base: blockfiUSDCoinAPY,
        max: maxBlockfiUSDCoinAPY,
    },
    'GUSD': {
        base: blockfiGeminiDollarsAPY,
        max: maxBlockfiGeminiDollarsAPY,
    },
    'PAX': {
        base: blockfiPaxosAPY,
        max: maxBlockfiPaxosAPY,
    },
    'PAXG': {
        base: blockfiPaxosGoldAPY,
        max: maxblockfiPaxosGoldAPY,
    },
    'BUSD': {
        base: blockfiBinanceUSDAPY,
        max: maxBlockfiBinanceUSDAPY,
    },
    'DAI': {
        base: blockfiDaiAPY,
        max: maxBlockfiDaiAPY,
    },
    'UNI': {
        base: blockfiUniswapAPY,
        max: maxBlockfiUniswapAPY,
    },
    'BAT': {
        base: blockfiBatAPY,
        max: '1.00% on first 4k BAT',
    },
    'DOGE': {
        base: '2.00%', // BlockFi never announced DOGE, ALGO, BCH in the US, but they show in the US on the rates page, so adding them to match
        max: '2.00%',
    },
    'ALGO': {
        base: '2.50%',
        max: '2.50%',
    },
    'BCH': {
        base: '3.00%',
        max: '3.00%',
    },
};

const maxUSDCoinAPYInt = '8.5% on first 20k USDC'
const usdCoinAPYInt = <div>{maxUSDCoinAPYInt} <br />7.5% for over 20k USDC</div>;

const maxGeminiDollarsAPYInt = '8.5% on first 20k GUSD'
const geminiDollarsAPYInt = <div>{maxGeminiDollarsAPYInt} <br />7.5% for over 20k GUSD</div>;

const maxPaxosAPYInt = '8.5% on first 20k PAX'
const paxosAPYInt = <div>{maxPaxosAPYInt} <br />7.5% for over 20k PAX</div>;

const maxBinanceUSDAPYInt = '8.5% on first 20k BUSD'
const binanceUSDAPYInt = <div>{maxBinanceUSDAPYInt} <br />7.5% for over 20k BUSD</div>;

const maxTetherAPYInt = '8.75% on first 20k USDT'
const tetherAPYInt = <div>{maxTetherAPYInt} <br />8% for over 20k USDT</div>;

const maxBitcoinAPYInt = '3.5% on first 0.1 BTC';
const bitcoinAPYInt = <div>{maxBitcoinAPYInt} <br />2.5% for over 0.1 BTC</div>;

const maxUniswapAPYInt = '3% on first 100 UNI';
const uniswapAPYInt = <div>{maxUniswapAPYInt} <br />2% for over 100 UNI</div>;

const maxBlockfiEthAPYInt = '3.5% on first 1.5 ETH';
const blockfiEthAPYInt = <div>{maxBlockfiEthAPYInt} <br />2.5% for 1.5 to 10 ETH<br />2% for over 10 ETH</div>;

export const ratesInt = {
    ...ratesUS,
    'BTC': {
        base: bitcoinAPYInt,
        max: maxBitcoinAPYInt,
    },
    'UNI': {
        base: uniswapAPYInt,
        max: maxUniswapAPYInt,
    },
    'USDC': {
        base: usdCoinAPYInt,
        max: maxUSDCoinAPYInt,
    },
    'GUSD': {
        base: geminiDollarsAPYInt,
        max: maxGeminiDollarsAPYInt,
    },
    'PAX': {
        base: paxosAPYInt,
        max: maxPaxosAPYInt,
    },
    'BUSD': {
        base: binanceUSDAPYInt,
        max: maxBinanceUSDAPYInt,
    },
    'USDT': {
        base: tetherAPYInt,
        max: maxTetherAPYInt,
    },
    'ADA': {
        base: '5.00%',
        max: '5.00%',
    },
    'ATOM': {
        base: '7.00%',
        max: '7.00%',
    },
    'AVAX': {
        base: '5.00%',
        max: '5.00%',
    },
    'DOT': {
        base: '10.00%',
        max: '10.00%',
    },
    'FIL': {
        base: '2.00%',
        max: '2.00%',
    },
    'SOL': {
        base: '5.00%',
        max: '5.00%',
    },
    'MATIC': {
        base: '5.00%',
        max: '5.00%',
    },
    'BNB': {
        base: '3.00%',
        max: '3.00%',
    },
    'LUNA': {
        base: '6.00%',
        max: '6.00%',
    },
    'FTT': {
        base: '3.00%',
        max: '3.00%',
    },
    'NEAR': {
        base: '7.00%',
        max: '7.00%',
    },
    'TRX': {
        base: '6.00%',
        max: '6.00%',
    },
    'S': {
        base: '3.00%',
        max: '3.00%',
    },
    'MANA': {
        base: '3.00%',
        max: '3.00%',
    },
    'AXS': {
        base: '3.00%',
        max: '3.00%',
    },
    'SAND': {
        base: '3.00%',
        max: '3.00%',
    },
    'EOS': {
        base: '3.00%',
        max: '3.00%',
    },
    'AAVE': {
        base: '3.00%',
        max: '3.00%',
    },
    'GALA': {
        base: '3.00%',
        max: '3.00%',
    },
    'RUNE': {
        base: '3.00%',
        max: '3.00%',
    },
    'SRM': {
        base: '3.00%',
        max: '3.00%',
    },
    'HBAR': {
        base: '3.00%',
        max: '3.00%',
    },
    'XTZ': {
        base: '3.00%',
        max: '3.00%',
    },
    'LRC': {
        base: '3.00%',
        max: '3.00%',
    },
    'OMG': {
        base: '3.00%',
        max: '3.00%',
    },
    'MKR': {
        base: '3.00%',
        max: '3.00%',
    },
    'COMP': {
        base: '3.00%',
        max: '3.00%',
    },
    'YFI': {
        base: '3.00%',
        max: '3.00%',
    },
    'CRV': {
        base: '3.00%',
        max: '3.00%',
    },
    'SUSHI': {
        base: '3.00%',
        max: '3.00%',
    },
    '1INCH': {
        base: '3.00%',
        max: '3.00%',
    },
    'GRT': {
        base: '5.00%',
        max: '5.00%',
    },
    'CHZ': {
        base: '3.00%',
        max: '3.00%',
    },
    'ENJ': {
        base: '3.00%',
        max: '3.00%',
    },
};
