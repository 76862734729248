export const vauldDateEffective = '4/7/22';

export const vauld7Apr22RatesUS = {
    BTC: { base: '4.60%', max: '6.70%' },
    DAI: { base: '9.41%', max: '12.68%' },
    TUSD: { base: '9.41%', max: '12.68%' },
    ETH: { base: '4.60%', max: '6.70%' },
    XRP: { base: '4.60%', max: '6.70%' },
    XLM: { base: '1.00%', max: '3.04%' },
    BAT: { base: '1.00%', max: '3.04%' },
    USDC: { base: '9.41%', max: '12.68%' },
    USDT: { base: '9.41%', max: '12.68%' },
    BUSD: { base: '9.41%', max: '12.68%' },
    FTT: { base: '1.00%', max: '3.04%' },
    KNC: { base: '1.00%', max: '3.04%' },
    ZRX: { base: '1.00%', max: '3.04%' },
    COMP: { base: '1.00%', max: '3.04%' },
    '1INCH': { base: '1.00%', max: '3.04%' },
    YFI: { base: '1.00%', max: '3.04%' },
    PAXG: { base: '1.00%', max: '3.04%' },
    CRV: { base: '1.00%', max: '3.04%' },
    BAL: { base: '1.00%', max: '3.04%' },
    MKR: { base: '1.00%', max: '3.04%' },
    MANA: { base: '1.00%', max: '3.04%' },
    SUSHI: { base: '1.00%', max: '3.04%' },
    AAVE: { base: '1.00%', max: '3.04%' },
    UNI: { base: '1.00%', max: '3.04%' },
    SNX: { base: '1.00%', max: '3.04%' },
    MATIC: { base: '4.08%', max: '7.23%' },
    GRT: { base: '1.00%', max: '3.04%' },
    ENJ: { base: '1.00%', max: '3.04%' },
    LINK: { base: '1.00%', max: '3.04%' },
    USDP: { base: '9.41%', max: '12.68%' },
    OMG: { base: '0.50%', max: '1.00%' },
    IOST: { base: '0.50%', max: '1.00%' },
    AMP: { base: '0.50%', max: '1.00%' },
    PERP: { base: '0.50%', max: '1.00%' },
    SHIB: { base: '0.50%', max: '1.00%' },
    BNB: { base: '0.50%', max: '1.00%' },
    SOL: { base: '4.08%', max: '4.08%' },
    ALICE: { base: '0.50%', max: '1.00%' },
    ALPHA: { base: '0.50%', max: '1.00%' },
    ANKR: { base: '0.50%', max: '1.00%' },
    ATA: { base: '0.50%', max: '1.00%' },
    AVA: { base: '0.50%', max: '1.00%' },
    AXS: { base: '24.19%', max: '40.00%' },
    BAKE: { base: '0.50%', max: '1.00%' },
    BAND: { base: '0.50%', max: '1.00%' },
    BCH: { base: '0.50%', max: '1.00%' },
    BNT: { base: '0.50%', max: '1.00%' },
    BTCST: { base: '0.50%', max: '1.00%' },
    CELR: { base: '0.50%', max: '1.00%' },
    CFX: { base: '0.50%', max: '1.00%' },
    CHR: { base: '0.50%', max: '1.00%' },
    COS: { base: '0.50%', max: '1.00%' },
    COTI: { base: '0.50%', max: '1.00%' },
    CTSI: { base: '0.50%', max: '1.00%' },
    DUSK: { base: '0.50%', max: '1.00%' },
    EGLD: { base: '0.50%', max: '1.00%' },
    ELF: { base: '0.50%', max: '1.00%' },
    ETC: { base: '0.50%', max: '1.00%' },
    FET: { base: '0.50%', max: '1.00%' },
    FLOW: { base: '0.50%', max: '1.00%' },
    S: { base: '0.50%', max: '1.00%' },
    INJ: { base: '0.50%', max: '1.00%' },
    IOTX: { base: '0.50%', max: '1.00%' },
    MDX: { base: '0.50%', max: '1.00%' },
    NEAR: { base: '0.50%', max: '1.00%' },
    OCEAN: { base: '0.50%', max: '1.00%' },
    ONT: { base: '0.50%', max: '1.00%' },
    POLS: { base: '0.50%', max: '1.00%' },
    REEF: { base: '0.50%', max: '1.00%' },
    WRX: { base: '0.50%', max: '1.00%' },
    XEC: { base: '0.50%', max: '1.00%' },
    XTZ: { base: '0.50%', max: '1.00%' },
    XVS: { base: '0.50%', max: '1.00%' },
    ZIL: { base: '0.50%', max: '1.00%' },
    ADA: { base: '3.56%', max: '4.07%' },
    DOGE: { base: '0.50%', max: '1.00%' },
    DOT: { base: '12.17%', max: '12.68%' },
    LTC: { base: '0.50%', max: '1.00%' },
    FIL: { base: '0.50%', max: '1.00%' },
    TRX: { base: '0.50%', max: '1.00%' },
    EOS: { base: '0.50%', max: '1.00%' },
    CAKE: { base: '40.34%', max: '42.58%' },
    ADX: { base: '0.50%', max: '1.00%' },
    FIRO: { base: '0.50%', max: '1.00%' },
    SXP: { base: '0.50%', max: '1.00%' },
    ATOM: { base: '0.50%', max: '1.00%' },
    IOTA: { base: '0.50%', max: '1.00%' },
    ZEC: { base: '0.50%', max: '1.00%' },
    AKRO: { base: '0.50%', max: '1.00%' },
    AUDIO: { base: '0.50%', max: '1.00%' },
    BADGER: { base: '0.50%', max: '1.00%' },
    CVC: { base: '0.50%', max: '1.00%' },
    DENT: { base: '0.50%', max: '1.00%' },
    DYDX: { base: '0.50%', max: '1.00%' },
    FORTH: { base: '0.50%', max: '1.00%' },
    GNO: { base: '0.50%', max: '1.00%' },
    HOT: { base: '0.50%', max: '1.00%' },
    LPT: { base: '0.50%', max: '1.00%' },
    LRC: { base: '0.50%', max: '1.00%' },
    NKN: { base: '0.50%', max: '1.00%' },
    NMR: { base: '0.50%', max: '1.00%' },
    NU: { base: '0.50%', max: '1.00%' },
    OGN: { base: '0.50%', max: '1.00%' },
    OXT: { base: '0.50%', max: '1.00%' },
    POLY: { base: '0.50%', max: '1.00%' },
    QNT: { base: '0.50%', max: '1.00%' },
    RLC: { base: '0.50%', max: '1.00%' },
    RSR: { base: '0.50%', max: '1.00%' },
    SAND: { base: '0.50%', max: '1.00%' },
    SKL: { base: '10.51%', max: '10.51%' },
    SRM: { base: '0.50%', max: '1.00%' },
    STMX: { base: '0.50%', max: '1.00%' },
    STORJ: { base: '0.50%', max: '1.00%' },
    TRB: { base: '0.50%', max: '1.00%' },
    UMA: { base: '0.50%', max: '1.00%' },
};

export const vauld7Apr22RatesInt = vauld7Apr22RatesUS;
